<script type="text/javascript"></script>
<template>
  <div style = "height:100%; background-color: #ffffff" class="d-flex justify-content-center align-items-center">
  
      <div class="" tabindex="-1" role="dialog" v-if="activesignin">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Sign out?</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>You are currently signed in to Flowe using the following account: {{ activeuseremail }}</p>
              <p>In order to continue the sign up process you need to sign out of this account before proceeding. Do you want to sign out now?</p>
            </div>
            <div class="modal-footer">
              
              <a v-if="activeuserorg" :href="'/'+activeuserorg+'/boards'" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
              <a v-else="" href="/" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
              <button type="button" class="btn btn-primary" @click="signoutandproceed()">Sign out</button>

            </div>
          </div>
        </div>
      </div>

    <div class = "container" v-if="!pageloading && activesignin==false">

      <div id="registerpage"> 
        <p class="logo"><a href ="https://flowe.io" ><img src="@/static/img/flowe.png" class="imglogo"></a></p>
        
        <div class="pagetitle" v-if="linkisvalid">
          <h2 class = "text-center"> You're almost done!</h2>
          <p class="text-center">Just one more step to go.</p>
          <p class="text-center">You are signing up with this email:<br><span class="text-subtle" v-text="register.email">name@domain.com</span></p>
        </div>

        <div class="pagetitle" v-if="!linkisvalid && !pageloading">
          <p class="text-center">This sign up link is invalid or has expired. </p>
          <p class ="text-center">
            <router-link class="actionprimary" to="/sign-up"> Click here to sign up again.</router-link>
          </p>
          
        </div>
        <form onsubmit="return false">
          <div class="form_wrapper" v-if="linkisvalid && !pageloading">

            <div class="form-group">
              <label>First name</label>
              <input type="text"  class="form-control" value="" autocomplete="new-password" placeholder="First name" onfocus="this.placeholder = '' " onblur="this.placeholder = 'First name'" v-model="register.firstname">
              <div class="text-danger" v-if="register.errors.firstname=='fieldrequired'">This field is required</div>
            </div>

            <div class="form-group">
              <label>Last name</label>
              <input type="text" class="form-control"  value="" autocomplete="new-password" placeholder="Last name" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Last name'" v-model="register.lastname">
              <div class="text-danger" v-if="register.errors.lastname=='fieldrequired'">This field is required</div>
            </div>

            <div class="form-group">
              <label>Password</label>
              <input type="text" class="form-control hide" name="passwordvoid" value="" >
              <input id="passwordholderid" type="password" class="form-control inputpassword" value=""  placeholder="Password" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Password';" autocomplete="new-password" v-model="register.password">

              <popoverpassword target="passwordholderid" :check="register.password" :mobiledevise ="mobiledevise" />

              <div class="text-danger" v-if="register.errors.password=='' && register.password == ''">This field is required</div>
              <div class="text-danger" v-if="register.errors.password=='passwordmin'">Password must be at least 8 characters</div>
              <div class="text-danger" v-if="register.errors.password=='passwordparam'">Passwords must contain at least one lowercase character, one uppercase character, and one number</div>
            </div>
            

            <div class="form-group">
              <label>Confirm password</label>
              <input type="text" class="form-control hide" name="passwordvoid" value="" >
              <input type="password" class="form-control" value="" placeholder="Confirm password" onfocus="this.placeholder = ''; showpassword2 = true" onblur="this.placeholder = 'Confirm password'; showpassword2 = false"  autocomplete="new-password" v-model="register.password2">


              <div class="text-danger" v-if="register.errors.password2=='fieldrequired'">This field is required</div>
              <div class="text-danger" v-if="register.errors.password2=='passwordmismatched'">Password and confirmed password does not match</div>
            </div>


            <div class="form-group" style ="padding-top: 20px ;" >
              <button type="submit" class="btn btn-block btn-lg btn-primary" v-on:click="doregister()" v-if="status=='idle'">Continue</button>
              <button type="button" class="btn btn-block btn-lg btn-primary disabled" disabled  v-if="status=='working'">Continue</button>
            </div>

          </div>
        </form>
      </div>
    </div>  
  </div>
</template>	
<style type="text/css" scoped>
:root{

  /* --G700: #157815; */
  --N900: #313338;
}


html,
body {
  color: var(--N900);
  height: 100%;
}

#app{
  background-color: #fff;
}

h1, h2{ text-align: center; }

.pagetitle{
  width: 100%;
  margin: 0 auto;
  max-width: 400px;
  margin-bottom: 1rem;
  margin-top: 3%;
}


.form_wrapper{
  margin: 0 auto;
  max-width: 350px;
}

#signuppage .form_wrapper,
#passwordreset .form_wrapper{ 
  max-width: 355px; 
}

#signuppage2 .form_wrapper{ max-width: 400px; }

#responsepage .signuppage .pagetitle{
  max-width: 500px;
}

.btn-google img{ 
  width: 24px;
  height: 24px;
  margin-right:.3em; 
}

p.logo .imglogo{ 
  width: 200px; 
}
p.logo{
  text-align: center;
  width: 100%;
  margin: auto;
}


</style>
<script>

  import Signinapi from '@/services/api/signin.js'
  import Userapi from '@/services/api/users'
  import popoverpassword from './popoverpassword'

   import auth from '@/cognito.js';

  export default{
    data(){
      return{
        status : 'idle',
        userid : null,
        sessionisvalid:true,
        linkisvalid:false,
        register : { email:"", firstname:"", lastname:"", password:"", password2:"", errors:{} },
        pageloading:true,
        uuid:"",
        mobiledevise:  window.innerwidth <= 576 ? 'right' : 'topright',
        passwordholderid : "",
        usertoken : "",
        activesignin : false,
        activeuseremail : "",
        activeuserorg: ""
      }
    },

    filters: {},

    mounted() { 

      this.usertoken = this.$cookie.get('usertoken');
      this.checkActivesignin()

      this.passwordholderid = "id"+Math.floor(Date.now() / 1000)

      document.title ="Flowe | Create your account"
      this.checkifvalid()
      
      
    },
    components:{popoverpassword},
    methods: {

      signoutandproceed(){


        this.$cookie.set('usertoken', '');
        this.$cookie.set('organizationkey', '');
        this.$cookie.set('signinmethod', "");
        localStorage.token = ""

        auth.logout();
        //this.$cookie.set('usertoken', '');
        this.usertoken = ""
        this.activesignin = false

      },
      checkActivesignin(){

        let self = this

        Userapi.Details( this.usertoken )

        .then( function(response){

          if( response.data.ok ){

            if( response.data.user.email != "" ){
              self.activesignin = true
              self.activeuseremail = response.data.user.email

              if( response.data.user.organization ){
                self.activeuserorg = response.data.user.organization.key
              }
              
            }
          }

        })
        .catch(error => console.log(error))
        .finally( () => {


        })


      },

      getWindowWidth(){
            //console.log('hello')
            if( window.innerWidth <= 576 ){
              console.log('topright')
              this.mobiledevise = 'topright'

            }else{

              this.mobiledevise = 'right'
            }
          },

            checkifvalid(){

              let self = this

              this.uuid = this.$route.query.authid

              Signinapi.Registercheck( this.uuid )

              .then( function(response){

                if( response.data.ok ){                    

                  //console.log( response.data.email )
                  self.register.email = response.data.email

                  self.linkisvalid = true
                }else{
                  self.linkisvalid = false
                }

              })
              .catch(error => console.log(error))
              .finally( () => {

                self.status = 'idle'
                self.pageloading = false
                $('#preloader').addClass('hide')

              })
            },

            /*usetoken2 : function( token ){


              let self = this

              this.status = 'working'
              this.pageloaded = false

              if( token != "" ){

                Userapi.Details( token )
                .then( function(response){

                  if( response.data.ok ){

                    self.$cookie.set('usertoken', response.data.response);
                    self.$router.push("/boards")


                  }else{
                    self.pageloaded = true
                  }

                  $('#preloader').addClass('hide')

                })
                .catch( () => {
               //self.pageloaded = true

               $('#preloader').addClass('hide')
             })
                .finally( () => {
               //self.pageloaded = true

               self.status = 'idle'

               $('#preloader').addClass('hide')
             })


              }else{
                this.pageloaded = true
                $('#preloader').addClass('hide')
              }



            },*/

            doregister : function(){



              let self = this

              this.status = 'working'
              console.log('register')
              Signinapi.Registerdo( { format:"token", v:this.uuid, email:this.register.email , firstname: this.register.firstname, lastname: this.register.lastname , password:this.register.password, password2:this.register.password2 } )
              .then( function(response){
                if( response.data.ok ){

                  self.$cookie.set('usertoken', response.data.response );
                  //self.$cookie.set('usertoken', response.data.response);
                  //self.$router.push('/boards')
                  location.href="/"

                  /*self.usetoken( response.data.response )  
                  console.log(response.data)*/
                }else{
                  //console.log(response.data)
                  self.register.errors = response.data.errors

                  self.checkifvalid()

                }

              })
              .catch(error => console.log(error))
              .finally( () => {

                self.status = 'idle'
              })

            }
          }
        }
      </script>